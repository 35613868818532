html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.container-border {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 15px auto;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column; /* To stack child elements vertically */
  align-items: center; /* To horizontally center child elements */
  justify-content: center; /* To vertically center child elements */
}

@media (max-width: 799px) {
  .login-container {
    border: 1px solid rgb(188, 188, 188);
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 15px auto;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column; /* To stack child elements vertically */
    align-items: center; /* To horizontally center child elements */
    justify-content: center; /* To vertically center child elements */
    width: 90%;
  }
}
@media (min-width: 800px) {
  .login-container {
    border: 1px solid rgb(188, 188, 188);
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 15px auto;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column; /* To stack child elements vertically */
    align-items: center; /* To horizontally center child elements */
    justify-content: center; /* To vertically center child elements */
    width: 40%;
  }
}

@media (min-width: 1301px) {
  .login-container {
    border: 1px solid rgb(188, 188, 188);
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 15px auto;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column; /* To stack child elements vertically */
    align-items: center; /* To horizontally center child elements */
    justify-content: center; /* To vertically center child elements */
    width: 20%;
  }
}

.menu-sider {
  background-color: #f8f9fa;
  min-height: 91vh;
  margin-top: 10px;
  height: auto;
  border: 1px solid rgb(188, 188, 188);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
  margin-right: 10px;
}

.collapse-button {
  position: absolute;
  top: 50%;
  right: -18px;
  transform: translateY(-50%);
  background: #f8f9fa;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 5;
  border: 1px solid #4ab8a8;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.home-card {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  background-color: "#edf2f2";
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.collapse-button:hover {
  background: #4ab8a8; /* Color on hover */
}

/* Adjust the icon size and color as needed */
.collapse-button .anticon {
  font-size: 16px; /* Adjust icon size */
  color: #000; /* Adjust icon color */
}

.nav-header {
  margin: 24px;
  min-height: 90vh;
  height: fit-content;
  border: 1px solid rgb(188, 188, 188);
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  background-color: #f8f9fa;
  padding: 20px;
}

.menu {
  width: 100%;
  border-radius: 5px;
  margin: 5;
  background-color: #f8f9fa;
}

.ant-menu-item-selected {
  color: #4ab8a8 !important;
  background-color: #b9cacf !important;
}
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #4ab8a8 !important;
}

.MuiTooltip-tooltipPlacementRight {
  background-color: #4ab8a8 !important;
  transform: translate(20px, -35px) !important;
  width: 170px !important;
}

.ant-layout-sider-children {
  background-color: #f8f9fa;
}

.content-container {
  margin: 15px;
  margin-top: 10px;
  min-height: 91vh;
  height: fit-content;
  border: 1px solid rgb(188, 188, 188);
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  background-color: #f8f9fa;
  padding: 20px;
}

.center-children {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-div-wrapper {
  display: flex;
  align-items: center;

  width: 100%;
}

.nav-button {
  white-space: nowrap;
  border-radius: 5px;
  color: #ffffff;
  background-color: #56575a;
  border-color: #56575a;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 290px;
}

.home-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  font-size: 2vw;
  width: auto;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.home-container {
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.spinner-style {
  color: #4ab8a8;
}

.form {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin: 15px auto;
  background-color: #faf8f8;
  width: 75%;
  padding: 15px;
}
.progress {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 25%;
  margin: 15px auto;
  background-color: #f8f9fa;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-item {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 15px;
}

.switch-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-primary {
  background-color: #4ab8a8;
  border-color: #4ab8a863;
}

.btn-primary:hover {
  background-color: #1a6d72;
  transition: 0.2s;
}
.btn-primary:active {
  background-color: #1a6d72;
  border-color: #4ab8a86f;
}

.table {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  background-color: #f8f9fa;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.grid-container {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: left;
  cursor: pointer;
  background: #f5f5f5;
}

.icon {
  color: #4ab8a8;
}

.card-header {
  background: #4ab8a863;
}

.span-style {
  margin-top: 8px;
  padding: 10px;
}

.card {
  margin: 10px;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #4ab8a8;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4ab8a8 !important;
}
.ant-tabs-tab:hover {
  color: #4ab8a8 !important;
}
.ant-tabs-tab.ant-tabs-tab-active:hover {
  color: #4ab8a8 !important;
}

.power_bi_container {
  width: 100%;
  height: 100%;
}
